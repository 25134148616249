<script lang="ts" setup>
import { useUnlinkHandler, type ILeaseRegisterSubleaseSummary } from '@register'
import { createColumnHelper } from '@tanstack/vue-table'
import type { DataTableColumn } from '@ui/components/DataTable/types'
import { useTanstackTableVariants } from '@ui/components/TanstackTable'

const props = withDefaults(
  defineProps<{
    subleases: ILeaseRegisterSubleaseSummary[] | undefined
    loading?: boolean
  }>(),
  {
    subleases: () => [],
    loading: false,
  },
)

const emit = defineEmits<{
  add: []
}>()

const leaseId = getCurrentLeaseId()

const { createDivisionURL } = useDivisions()

const columnHelper = createColumnHelper<ILeaseRegisterSubleaseSummary>()
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: ({ getValue }) => title(getValue()),
  }),
  columnHelper.accessor('lessee', {
    header: 'Lessee',
  }),
  columnHelper.accessor('commencementDate', {
    header: 'Start date',
  }),
  columnHelper.accessor('expiryDate', {
    header: 'End date',
  }),
  columnHelper.accessor('baseRent', {
    header: 'Base Rent',
  }),
  columnHelper.accessor('isPublished', {
    header: 'Published',
  }),
  columnHelper.display({
    id: 'actions',
  }),
]

const onUnlinkHandler = useUnlinkHandler({
  title: 'Unlink sublease',
  body: 'Are you sure you want to unlink this sublease?',
  successMessage: 'Sublease unlinked successfully',
  errorMessage: 'Failed to unlink sublease',
  loadingMessage: 'Unlinking sublease...',
  confirmInputText: 'UNLINK',
  isSublease: true,
})

function onUnlink(id: string) {
  onUnlinkHandler({ parentId: leaseId, subleaseId: id })
}

const variant = useTanstackTableVariants({
  variants: {
    hasScroller: {
      true: {
        wrapper: 'overflow-visible',
      },
    },
  },
})
</script>

<template>
  <TanstackTable
    :data="subleases"
    :columns="columns"
    :loading="loading"
    class="max-w-full"
    :variant="variant"
  >
    <template #empty>
      <LeasePageEmpty
        title="There is no sublease linked to this headlease"
        button="Add a sublease"
        @click="emit('add')"
      />
    </template>

    <template
      #item-name="{
        item: obj,
        value,
      }: DataTableColumn<
        ILeaseRegisterSubleaseSummary,
        ILeaseRegisterSubleaseSummary['name']
      >"
    >
      <NuxtLink
        color="link"
        class="hover:text-primary flex cursor-pointer flex-col px-0"
        :to="createDivisionURL(`/lease/${obj.id}/lease`)"
      >
        {{ value }}
      </NuxtLink>
    </template>

    <template
      #item-status="{
        item: obj,
        value,
      }: DataTableColumn<
        ILeaseRegisterSubleaseSummary,
        ILeaseRegisterSubleaseSummary['status']
      >"
    >
      <NuxtLink
        color="link"
        class="hover:text-primary flex cursor-pointer flex-col px-0"
        :to="createDivisionURL(`/lease/${obj.id}/lease`)"
      >
        {{ value }}
      </NuxtLink>
    </template>

    <template
      #item-lessee="{
        item: obj,
        value,
      }: DataTableColumn<
        ILeaseRegisterSubleaseSummary,
        ILeaseRegisterSubleaseSummary['lessee']
      >"
    >
      <div class="flex flex-col">
        <NuxtLink
          color="link"
          class="hover:text-primary flex cursor-pointer flex-col px-0"
          :to="createDivisionURL(`/lease/${obj.id}/lease`)"
        >
          {{ value }}
        </NuxtLink>
      </div>
    </template>

    <template
      #item-isPublished="{
        value,
      }: DataTableColumn<
        ILeaseRegisterSubleaseSummary,
        ILeaseRegisterSubleaseSummary['isPublished']
      >"
    >
      <span
        v-if="value === true"
        class="bg-success rounded-lg px-3 py-1 text-black"
      >
        Published
      </span>

      <span v-else class="bg-warning rounded-lg px-3 py-1 text-black">
        Unpublished
      </span>
    </template>

    <template
      #item-actions="{
        item: obj,
      }: DataTableColumn<ILeaseRegisterSubleaseSummary>"
    >
      <ActionMenu v-if="obj.type !== 'lease'" class="relative">
        <ActionMenuItem
          class="hover:bg-red hover:text-red-50"
          @click="onUnlink(obj.id)"
        >
          Unlink
        </ActionMenuItem>
      </ActionMenu>
    </template>
  </TanstackTable>
</template>
